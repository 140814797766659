import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/forge/tayo-software.com/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/forge/tayo-software.com/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_plugin_mjs_1QO0gqa6n2 from "/home/forge/tayo-software.com/node_modules/nuxt/dist/head/runtime/plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/forge/tayo-software.com/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_OrkQhMqHci from "/home/forge/tayo-software.com/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/home/forge/tayo-software.com/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_vuetify_js_8NhHJigKc1 from "/home/forge/tayo-software.com/plugins/vuetify.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_head_runtime_plugin_mjs_1QO0gqa6n2,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_OrkQhMqHci,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_vuetify_js_8NhHJigKc1
]