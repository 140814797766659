import { createI18n } from 'vue-i18n'
import de from '~/locales/de'
import en from '~/locales/en'
import fr from '~/locales/fr'

const messages = {
  de,
  en,
  fr
}
const i18n = createI18n({
  strategy: 'prefix_except_default', // only for nuxt i18n
  warnHtmlInMessage: false, // 'off', -- If legacy mode
  defaultLocale: 'en',
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  legacy: false, // necessary with Vue 3
  messages
})

i18n.path = (link) => {
  if (i18n.locale === i18n.fallbackLocale) {
    return `/${link}`
  }
  return `/${i18n.locale}/${link}`
}
export default i18n
