<template>
  <v-layout
    class="banner main-content heightScreen"
  >
    <v-container v-if="!isReady" style="height: 100vh;">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col md="6" cols="12">
          <v-progress-linear
            color="#FFCC00"
            indeterminate
            rounded
            label="Progress"
            title="Progress"
            height="6"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="isReady" class="fill-height d-flex justify-center flex-column">
      <v-row justify="end" class="position-relative text-left">
        <v-col
          :xs="colSize[0]"
          :sm="colSize[0]"
          md="7"
          lg="7"
          cols="12"
          order="2"
          order-sm="2"
          order-md="1"
          class="d-flex flex-column align-start contentTop tagline"
        >
          <h1 :style="txtMargin">
            {{ breakWords(tagline) }}
          </h1>
        </v-col>
        <v-col
          md="5"
          lg="5"
          :xs="colSize[1]"
          :sm="colSize[1]"
          cols="12"
          order="-1"
          order-sm="-1"
          order-md="2"
          class="justify-center  d-flex flex-column col-banner-image text-center"
        >
          <nuxt-img
            :src="imageSource"
            :alt="imageAlt"
            class="banner-image mb-2"
            :width="imgWidth"
            :style="imgMargin"
            placeholder
          />
          <!--          sizes="sm:80vw md:50vw lg:38vw xl:35vw"-->
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          order="3"
          class="hidden-md-and-up"
        >
          <div class="d-flex flex-column justify-end align-end">
            <TayoButton button-type="demo" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import { mdiArrowRight } from '@mdi/js'

export default {
  props: {
    tagline: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => ({})
    },
    button: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    mdiArrowRight,
    isReady: false,
    imgWidth: 720,
    imgMargin: 'margin-left:-100px',
    txtMargin: 'margin-top:140px',
    colSize: [12, 12]
  }),
  computed: {
    buttonText () {
      return this.button && this.button.text ? this.button.text : this.$t('button.community')
    },
    buttonTarget () {
      return this.button && this.button.to ? this.button.to : ('/' + this.$i18n.locale + '/tayo-map')
    },
    imageSource () {
      return this.image && this.image.src ? this.image.src : '/img/svg/beachHouse.svg'
    },
    imageAlt () {
      return this.image && this.image.alt ? this.image.alt : this.$t('alt.banner_illustration')
    }
  },
  mounted () {
    const vm = this
    this.calculateCol()
    this.imgWidth = this.calculeWidth()
    this.imgMargin = this.calculateMargin()
    this.txtMargin = this.calculateMarginTxt()
    window.addEventListener('resize', () => {
      vm.imgWidth = vm.calculeWidth()
      vm.imgMargin = vm.calculateMargin()
      vm.txtMargin = vm.calculateMarginTxt()
    })
    window.addEventListener(
      'orientationchange',
      this.calculateCol
    )
    this.isReady = true
  },
  methods: {
    calculateCol () {
      if ((
        navigator.userAgent.match(/Android/i) ||
              navigator.userAgent.match(/iPhone/i)
      ) && window.matchMedia('(orientation: portrait)')
      ) {
        this.colSize = [12, 12]
      } else {
        this.colSize = [7, 5]
      }
    },
    calculateMarginTxt () {
      if (this.$vuetify.display.mobile) {
        return 'margin-top:0;'
      }
      let size = 0
      size = (window.innerHeight / 6)
      return 'margin-top:' + size + 'px'
    },
    calculeWidth () {
      if (this.$vuetify.display.mobile) {
        return '80%'
      }
      let size = 0
      size = (window.innerWidth / 2.3)
      return size
    },
    calculateMargin () {
      if (this.$vuetify.display.mobile) {
        return 'margin:auto;'
      }
      let margin = 0
      let marginTop = 0
      margin = (window.innerWidth / 18)
      marginTop = (window.innerHeight / 7)

      if (!this.align || this.align === 'left') {
        return 'margin-left:-' + margin + 'px; padding-top:' + marginTop + 'px;'
      } else {
        return 'margin-right:-' + margin + 'px; padding-top:' + marginTop + 'px;'
      }
    }
  }
}
</script>
<style lang="scss">
    .banner-image{
      max-width: 1200px;
    }

    .banner{
        background: black;
        /* min-height: 500px; */

      @media (max-width: 599px){
        padding-right:24px;
        padding-left:24px;
      }

        @media (min-width: 600px) and (max-width: 959px){
                padding-right:48px;
                padding-left:48px;
        }
        @media (min-width: 960px) and (max-width: 1263px){
          padding-right:100px;
          padding-left:100px;
        }

        @media (min-width: 1264px){
          padding-right: calc(100vw/10);
          padding-left: calc(100vw/10);
          h1{
            padding-right: 20px;
          }
        }
      h1{
        word-break: break-word;
      }

      .contentTop{
        z-index:100;
      }

      @media (max-width: 960px) {
        .col-banner-image{
          img{
            width:100%;
          }
        }
      }
      @media (max-width: 600px) and  (max-height: 600px) {
        .col-banner-image{
          img{
            width:60%;
          }
        }
      }

      @media (min-width: 960px) and (min-height: 500px) {
        .col-banner-image{
          img{
            padding-top: 20%;
          }
        }
      }
    }

</style>
