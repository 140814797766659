
import * as ipxRuntime$G3YXXZDEkm from '/home/forge/tayo-software.com/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 600,
    "md": 960,
    "lg": 1264,
    "xl": 1904,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$G3YXXZDEkm, defaults: {} }
}
        