<template>
  <div>
    <NuxtLayout />
  </div>
</template>
<script setup>
const nuxtApp = useNuxtApp()
useHead({
  meta: {
    name: 'google-site-verification',
    content: 'WN-yIvE2XHuRNZWbdH6BVbIYGLlFVVEmnBhIqPPVzWc'
  },
  script: [
    {
      type: 'application/javascript',
      src: 'https://www.googletagmanager.com/gtm.js?id=GTM-5VGJFXR',
      async: true
    },
    { type: 'application/javascript', src: '/js/tag-manager.js', defer: true },
    {
      type: 'application/javascript',
      src: '/js/linkedin-tag.js',
      body: true,
      defer: true
    },
    {
      type: 'text/javascript',
      src: '//js.hs-scripts.com/5060543.js',
      body: true,
      defer: true,
      id: 'hs-script-loader'
    }
    // { type: 'application/javascript', src: '/_nuxt/node_modules/default-passive-events/dist/index.js' }
  ]
})

nuxtApp.hook('page:finish', () => {
  window.scrollTo(0, 0)
})
</script>
