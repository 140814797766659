export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appAssetsPath = {}

export const appCdnURL = ""

export const appHead = {"meta":[{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-title","content":"Tayo"},{"name":"author","content":"Tayo"},{"name":"theme-color","content":"#000000"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Tayo - The property management platform"},{"property":"og:site_name","content":"Tayo"},{"property":"og:description","content":"Tayo, the digital platform for more fluidity and comfort in property management"}],"link":[{"rel":"shortcut icon","href":"/_nuxt/icons/64x64.2f02112b.png"},{"rel":"apple-touch-icon","href":"/_nuxt/icons/512x512.maskable.2f02112b.png","sizes":"512x512"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[{"children":"if ('serviceWorker' in navigator) {\n  window.addEventListener('load', () => navigator.serviceWorker.register('/sw.js'))\n}"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"Tayo","htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appKeepalive = false