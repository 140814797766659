import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Comment_45cela_45fonctionne_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Comment-cela-fonctionne.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Legal_45Mentions_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Legal-Mentions.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Pricing.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Request_45demo_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Request-demo.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Solutions.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Tayo_45Map_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Tayo-Map.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/Team.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47blog_47_91article_93_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/blog/[article].vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47blog_47index_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/blog/index.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47index_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/index.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47tayo_45mag_47Archives_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/tayo-mag/Archives.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47tayo_45mag_47index_46vueMeta } from "/home/forge/tayo-software.com/pages/[locale]/tayo-mag/index.vue?macro=true";
import { meta as _47home_47forge_47tayo_45software_46com_47pages_47index_46vueMeta } from "/home/forge/tayo-software.com/pages/index.vue?macro=true";
export default [
  {
    name: "locale-Comment-cela-fonctionne",
    path: "/:locale/Comment-cela-fonctionne",
    file: "/home/forge/tayo-software.com/pages/[locale]/Comment-cela-fonctionne.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Comment_45cela_45fonctionne_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Comment_45cela_45fonctionne_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Comment-cela-fonctionne.vue").then(m => m.default || m)
  },
  {
    name: "locale-How-It-Works",
    path: "/:locale/How-It-Works",
    file: "/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue").then(m => m.default || m)
  },
  {
    name: "locale-Legal-Mentions",
    path: "/:locale/Legal-Mentions",
    file: "/home/forge/tayo-software.com/pages/[locale]/Legal-Mentions.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Legal_45Mentions_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Legal_45Mentions_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Legal-Mentions.vue").then(m => m.default || m)
  },
  {
    name: "locale-Pricing",
    path: "/:locale/Pricing",
    file: "/home/forge/tayo-software.com/pages/[locale]/Pricing.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Pricing.vue").then(m => m.default || m)
  },
  {
    name: "locale-Request-demo",
    path: "/:locale/Request-demo",
    file: "/home/forge/tayo-software.com/pages/[locale]/Request-demo.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Request_45demo_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Request_45demo_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Request-demo.vue").then(m => m.default || m)
  },
  {
    name: "locale-Solutions",
    path: "/:locale/Solutions",
    file: "/home/forge/tayo-software.com/pages/[locale]/Solutions.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Solutions.vue").then(m => m.default || m)
  },
  {
    name: "locale-Tayo-Map",
    path: "/:locale/Tayo-Map",
    file: "/home/forge/tayo-software.com/pages/[locale]/Tayo-Map.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Tayo_45Map_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Tayo_45Map_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Tayo-Map.vue").then(m => m.default || m)
  },
  {
    name: "locale-TayoImmomig",
    path: "/:locale/TayoImmomig",
    file: "/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue").then(m => m.default || m)
  },
  {
    name: "locale-Team",
    path: "/:locale/Team",
    file: "/home/forge/tayo-software.com/pages/[locale]/Team.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Team.vue").then(m => m.default || m)
  },
  {
    name: "locale-blog-article",
    path: "/:locale/blog/:article",
    file: "/home/forge/tayo-software.com/pages/[locale]/blog/[article].vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47blog_47_91article_93_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47blog_47_91article_93_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/blog/[article].vue").then(m => m.default || m)
  },
  {
    name: "locale-blog",
    path: "/:locale/blog",
    file: "/home/forge/tayo-software.com/pages/[locale]/blog/index.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47blog_47index_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47blog_47index_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "locale",
    path: "/:locale",
    file: "/home/forge/tayo-software.com/pages/[locale]/index.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47index_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47index_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-tayo-mag-Archives",
    path: "/:locale/tayo-mag/Archives",
    file: "/home/forge/tayo-software.com/pages/[locale]/tayo-mag/Archives.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47tayo_45mag_47Archives_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47tayo_45mag_47Archives_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/tayo-mag/Archives.vue").then(m => m.default || m)
  },
  {
    name: "locale-tayo-mag",
    path: "/:locale/tayo-mag",
    file: "/home/forge/tayo-software.com/pages/[locale]/tayo-mag/index.vue",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47tayo_45mag_47index_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47tayo_45mag_47index_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/tayo-mag/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    file: "/home/forge/tayo-software.com/pages/index.vue",
    children: [],
    url: "/",
    meta: _47home_47forge_47tayo_45software_46com_47pages_47index_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47index_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "equipe",
    path: "/fr/equipe",
    file: "/home/forge/tayo-software.com/pages/[locale]/Team.vue",
    url: "/fr/equipe",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Team.vue").then(m => m.default || m)
  },
  {
    name: "comment-cela-fonctionne",
    path: "/fr/comment-cela-fonctionne",
    file: "/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue",
    url: "/fr/comment-cela-fonctionne",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue").then(m => m.default || m)
  },
  {
    name: "prix",
    path: "/fr/prix",
    file: "/home/forge/tayo-software.com/pages/[locale]/Pricing.vue",
    url: "/fr/prix",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Pricing.vue").then(m => m.default || m)
  },
  {
    name: "solution",
    path: "/fr/solution",
    file: "/home/forge/tayo-software.com/pages/[locale]/Solutions.vue",
    url: "/fr/solution",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Solutions.vue").then(m => m.default || m)
  },
  {
    name: "tayo-immomig-en",
    path: "/en/tayo-immomig",
    file: "/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue",
    url: "/en/tayo-immomig",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue").then(m => m.default || m)
  },
  {
    name: "tayo-immomig-fr",
    path: "/fr/tayo-immomig",
    file: "/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue",
    url: "/fr/tayo-immomig",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue").then(m => m.default || m)
  },
  {
    name: "tayo-immomig-de",
    path: "/de/tayo-immomig",
    file: "/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue",
    url: "/de/tayo-immomig",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47TayoImmomig_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/TayoImmomig.vue").then(m => m.default || m)
  },
  {
    name: "carte-tayo",
    path: "/fr/carte-tayo",
    file: "/home/forge/tayo-software.com/pages/[locale]/Tayo-Map.vue",
    url: "/fr/carte-tayo",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Tayo_45Map_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Tayo_45Map_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Tayo-Map.vue").then(m => m.default || m)
  },
  {
    name: "equipe",
    path: "/fr/equipe",
    file: "/home/forge/tayo-software.com/pages/[locale]/Team.vue",
    url: "/fr/equipe",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Team_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Team.vue").then(m => m.default || m)
  },
  {
    name: "wie-es-funktioniert",
    path: "/de/wie-es-funktioniert",
    file: "/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue",
    url: "/de/wie-es-funktioniert",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47How_45It_45Works_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/How-It-Works.vue").then(m => m.default || m)
  },
  {
    name: "preis",
    path: "/de/preis",
    file: "/home/forge/tayo-software.com/pages/[locale]/Pricing.vue",
    url: "/de/preis",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Pricing_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Pricing.vue").then(m => m.default || m)
  },
  {
    name: "losung",
    path: "/de/losung",
    file: "/home/forge/tayo-software.com/pages/[locale]/Solutions.vue",
    url: "/de/losung",
    children: [],
    meta: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta,
    alias: _47home_47forge_47tayo_45software_46com_47pages_47_91locale_93_47Solutions_46vueMeta?.alias || [],
    component: () => import("/home/forge/tayo-software.com/pages/[locale]/Solutions.vue").then(m => m.default || m)
  }
]