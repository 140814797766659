// plugins/vuetify.js

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import {
  VApp,
  VAppBar,
  VBtn,
  VFadeTransition,
  VSlideXTransition,
  VAvatar,
  VChip,
  VLayout,
  VMain,
  VFooter,
  VTextField,
  VTextarea,
  VCheckbox,
  VSwitch,
  VThemeProvider,
  VImg,
  VTooltip,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelText,
  VExpansionPanelTitle,
  VProgressLinear,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VCardActions,
  VCardItem,
  VHover,
  VTable,
  VIcon,
  VList,
  VListItem,
  VListItemAction,
  VListItemTitle,
  VListItemSubtitle,
  VNavigationDrawer,
  VSpacer,
  VAppBarNavIcon,
  VMenu,
  VInput,
  VCol,
  VForm,
  VRow,
  VContainer,
  VItemGroup,
  VSlider,
  VSlideGroupItem,
  VSlideGroup,
  VProgressCircular,
  VPagination,
  VSelect,
  VAlert
} from 'vuetify/components'

import * as directives from 'vuetify/directives'
import i18n from '../helpers/i18n.js'
// import { Loader } from '@googlemaps/js-api-loader'
import TayoButton from '~/components/partials/TayoButton.vue'
import BackButton from '~/components/partials/BackButton.vue'
import TayoBanner from '~/components/TayoBanner.vue'

export default defineNuxtPlugin((nuxtApp) => {
  const light = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#FFCC00',
      // 'primary-darken-1': '#3700B3',
      secondary: '#0042D0',
      // 'secondary-darken-1': '#018786',
      tertiary: '#FF4E00',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      white: '#FFFFFF'
    }
  }

  const vuetify = createVuetify({
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    display: {
      mobileBreakpoint: 'md'
    },
    components: {
      VApp,
      VAppBar,
      VAvatar,
      VBtn,
      VFadeTransition,
      VSlideXTransition,
      VChip,
      VLayout,
      VMain,
      VFooter,
      VTextField,
      VTextarea,
      VCheckbox,
      VSwitch,
      VThemeProvider,
      VImg,
      VTooltip,
      VExpansionPanels,
      VExpansionPanel,
      VExpansionPanelText,
      VExpansionPanelTitle,
      VProgressLinear,
      VCard,
      VCardTitle,
      VCardSubtitle,
      VCardText,
      VCardActions,
      VCardItem,
      VHover,
      VTable,
      VIcon,
      VList,
      VListItem,
      VListItemAction,
      VListItemTitle,
      VListItemSubtitle,
      VNavigationDrawer,
      VSpacer,
      VAppBarNavIcon,
      VMenu,
      VInput,
      VCol,
      VForm,
      VRow,
      VContainer,
      VItemGroup,
      VSlider,
      VSlideGroupItem,
      VSlideGroup,
      VSelect,
      VAlert,
      VProgressCircular,
      VPagination
    },
    customVariables: ['~/assets/css/variables.scss'],
    theme: {
      defaultTheme: 'light',
      themes: {
        light
      }
    },
    directives,
    defaults: {
      global: {
        ripple: false
      }
    },
    lang: {
      t: (key, ...params) => i18n.t(key, params)
    }
  })

  nuxtApp.vueApp.mixin({
    methods: {
      formatNumber (x) {
        return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") : 0
      },
      breakWords (sentence, type) {
        if (this.$i18n.locale !== 'de') {
          return sentence
        }
        const words = ['Immobilienverwaltung', 'Immobilienbranche', 'Preisgestaltung']
        const brokenWords = ['Immobilien-verwaltung', 'Immobilien-branche', 'Preis-gestaltung']
        let newSentence = sentence
        const orientationPortrait = window.matchMedia('(orientation: portrait)')
        if ((!type && (this.$vuetify.display.width < 600 || (this.$vuetify.display.width < 959 && !orientationPortrait) ||
        (this.$vuetify.display.width > 959 && this.$vuetify.display.width < 1904))) || (type === 'smallTitle' && this.$vuetify.display.width < 400)) {
          for (let i = 0; i < words.length; i++) {
            newSentence = newSentence.replace(words[i], brokenWords[i])
          }
        }
        return newSentence
      }
    }
  })

  nuxtApp.vueApp.component('BackButton', BackButton)
  nuxtApp.vueApp.component('TayoButton', TayoButton)
  nuxtApp.vueApp.component('TayoBanner', TayoBanner)

  nuxtApp.vueApp.use(vuetify, {
    iconfont: 'mdiSvg'
  })
  nuxtApp.vueApp.use(i18n)
})
