import i18n from '../helpers/i18n.js'

function buildAlt (name) {
  return i18n.global.t('alt.tayo_map_image') + ' - ' + name
}
function buildAltMoteur (name) {
  return i18n.global.t('alt.tayo_map_moteur') + ' - ' + name
}

const agencies = [
  {
    name: 'Tayo Sofware',
    agencyId: 'TAYO',
    line1: 'Bat F, EPFL Innovation Park',
    line2: '',
    zip: '1015',
    city: 'Lausanne',
    location: {
      lat: 46.5167253,
      lng: 6.5594771
    },
    url: 'https://tayo-software.com/' + i18n.global.locale,
    img: '/img/logo/TAYO-black.svg',
    alt: buildAlt('Tayo Software')
  },
  {
    name: 'DNV IMMOBILIER - Agence immobilière du Nord Vaudois',
    agencyId: 'DNV',
    line1: 'Rue du Valentin 9,',
    line2: '',
    zip: '1400',
    city: 'Yverdon-les-Bains',
    location: {
      lat: 46.7773185,
      lng: 6.6421656
    },
    url: 'https://dnv-immobilier.ch/',
    img: '/img/clients/dnv.png',
    alt: buildAlt('DNV IMMOBILIER - Agence immobilière du Nord Vaudois')
  },
  {
    name: 'Beaver Immobilier SA',
    agencyId: 'BEAVER',
    line1: 'Avenue des Morgines 12',
    line2: '',
    zip: '1213',
    city: 'Petit-Lancy',
    location: {
      lat: 46.1911204,
      lng: 6.1090187
    },
    url: 'https://www.beaver-immo.ch/',
    img: '/img/clients/beaver.jpg',
    alt: buildAlt('Beaver Immobilier SA')
  },
  {
    name: 'Bernard Nicod - Lausanne Gare',
    agencyId: 'BERNARDNICODLAUS',
    line1: 'Av de la Gare 26',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.517290,
      lng: 6.632670
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Lausanne Gare')
  },
  {
    name: 'Bernard Nicod - Vevey',
    agencyId: 'BERNARDNICODVEVEY',
    line1: 'Rue de la Madeleine 37',
    line2: '',
    zip: '1800',
    city: 'Vevey',
    location: {
      lat: 46.462140,
      lng: 6.842030
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Vevey')
  },
  {
    name: 'Bernard Nicod - Genève',
    agencyId: 'BERNARDNICODGEN',
    line1: 'Av. de la Gare des Eaux-Vives 2',
    line2: '',
    zip: '1207',
    city: 'Genève',
    location: {
      lat: 46.200210,
      lng: 6.165990
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Genève')
  },
  {
    name: 'Bernard Nicod - Yverdon',
    agencyId: 'BERNARDNICODYV',
    line1: 'Rue de la Plaine 39',
    line2: '',
    zip: '1400',
    city: 'Yverdon-les-Bains',
    location: {
      lat: 46.777540,
      lng: 6.643890
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Yverdon')
  },
  {
    name: 'Bernard Nicod - Monthey',
    agencyId: 'BERNARDNICODMONT',
    line1: 'Rue de Venise 3',
    line2: '',
    zip: '1870',
    city: 'Monthey',
    location: {
      lat: 46.251480,
      lng: 6.948970
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Monthey')
  },
  {
    name: 'Bernard Nicod - Morges',
    agencyId: 'BERNARDNICODMOR',
    line1: 'Rue de la Gare 11',
    line2: '',
    zip: '1110',
    city: 'Morges',
    location: {
      lat: 46.52946020671018,
      lng: 6.496993954738756
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Morges')
  },
  {
    name: 'Bernard Nicod - Nyon',
    agencyId: 'BERNARDNICODNYON',
    line1: 'Chem. d\'Eysins 51',
    line2: '',
    zip: '1260',
    city: 'Nyon',
    location: {
      lat: 46.40460357620887,
      lng: 6.225082343672856
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Nyon')
  },
  {
    name: 'Bernard Nicod - Lausanne Benjamin Constant',
    agencyId: 'BERNARDNICODBENJ',
    line1: 'Av. Benjamin-Constant 1',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.54457488413731,
      lng: 6.632949760271705
    },
    url: 'https://www.bernard-nicod.ch/',
    img: '/img/clients/bn.png',
    alt: buildAlt('Bernard Nicod - Lausanne Benjamin Constant')
  },
  {
    name: 'Bory Immobilier',
    agencyId: 'BORY',
    line1: 'Avenue Rosemont 8',
    line2: '',
    zip: '1208 ',
    city: 'Genève',
    location: {
      lat: 46.20282837444034,
      lng: 6.1718595920559345
    },
    url: 'https://www.bory.ch/',
    img: '/img/clients/bory.png',
    alt: buildAlt('Bory Immobilier')
  },
  //  {
  //    name: 'Burnier Immobilier',
  //    agencyId: 'BURNIER',
  //    line1: 'Rue César Soulié 3',
  //    line2: '',
  //    zip: '1260',
  //    city: 'Nyon',
  //    location: {
  //      lat: 46.38411765784927,
  //      lng: 6.243228300000001
  //    },
  //    url: 'https://www.burnier.ch/',
  //    img: '/img/clients/burnier.svg',
  //    alt: buildAlt('Burnier Immobilier')
  //  },
  {
    name: 'Coucou&Co SARL',
    agencyId: 'COUCOU',
    line1: 'Rue de Technopôle 4',
    line2: '',
    zip: '3960',
    city: 'Sierre',
    location: {
      lat: 46.28369308831221,
      lng: 7.5388751
    },
    url: 'https://coucounco.ch/',
    img: '/img/clients/coucou-co.png',
    alt: buildAlt('Coucou&Co')
  },
  {
    name: 'de Rham SA',
    agencyId: 'DERHAM',
    line1: 'Av. Mon-Repos 14',
    line2: '',
    zip: '1005',
    city: 'Lausanne',
    location: {
      lat: 46.51900390349326,
      lng: 6.640678861370623
    },
    url: 'https://www.derham.ch/',
    img: '/img/clients/derham.png',
    alt: buildAlt('de Rham SA')
  },
  {
    name: 'Fondation Immobilière de la Ville de Carouge',
    agencyId: 'CAROUGE',
    line1: 'Rue des Epinettes 12ter',
    line2: '',
    zip: '1227',
    city: 'Carouge',
    location: {
      lat: 46.190515095557814,
      lng: 6.135472353972034
    },
    url: 'https://fivc.ch/',
    img: '/img/clients/carougelogo.png',
    alt: buildAlt('Fondation Immobilière de la Ville de Carouge')
  },
  {
    name: 'Gestigroup Sàrl',
    agencyId: 'GESTIGROUP',
    line1: 'Petite rue 3',
    line2: '',
    zip: '1304',
    city: 'Cossonay',
    location: {
      lat: 46.61502339610625,
      lng: 6.507694369314689
    },
    url: 'https://gestigroup.ch/',
    img: '/img/clients/gestigroup.png',
    alt: buildAlt('Gestigroup Sàrl')
  },
  {
    name: 'IMOS Immobilier et Conseils',
    agencyId: 'IMOS',
    line1: 'Rue Centrale 10',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.5211465197758,
      lng: 6.633279038629377
    },
    url: 'http://imos-immobilier.ch/',
    img: '/img/clients/imos.png',
    alt: buildAlt('IMOS Immobilier et Conseils')
  },
  {
    name: 'Jacques Lugrin SA',
    agencyId: 'LUGRIN',
    line1: 'Rue de la Gare 12',
    line2: '',
    zip: '1110',
    city: 'Morges',
    location: {
      lat: 46.50897124523668,
      lng: 6.496297613162652
    },
    url: 'https://lugrinimmobilier.ch/',
    img: '/img/clients/jacques_lugrin.png',
    alt: buildAlt('Jacques Lugrin SA')
  },
  {
    name: 'Merse IMMO',
    agencyId: 'MERSEIMMO',
    line1: 'Spitalstrasse 12',
    line2: '',
    zip: '2502',
    city: 'Biel',
    location: {
      lat: 47.13738829799886,
      lng: 7.241442338629377
    },
    url: 'https://merseimmo.ch/',
    img: '/img/clients/merseimmo.png',
    alt: buildAlt('Merse IMMO')
  },
  {
    name: 'Régie de Fribourg SA',
    agencyId: 'FRIBOURG',
    line1: 'Rue Saint-Pierre 1',
    line2: '',
    zip: '1700',
    city: 'Fribourg',
    location: {
      lat: 46.80413809487469,
      lng: 7.153929792601411
    },
    url: 'https://www.rfsa.ch/fr',
    img: '/img/clients/rfsa.jpg',
    alt: buildAlt('Régie de Fribourg SA')
  },
  {
    name: 'Régie Duboux SA',
    agencyId: 'DUBOUXSA',
    line1: 'Rue du Petit-Chêne 26',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.51910440286511,
      lng: 6.631234846027967
    },
    url: 'https://www.regieduboux.ch/',
    img: '/img/clients/duboux.png',
    alt: buildAlt('Régie Duboux'),
    blackBackground: true
  },
  {
    name: 'Régie Duboux SA - Succursale de La Riviera',
    agencyId: 'DUBOUXSARIVIERA',
    line1: 'Av. de la Gare 17/19',
    line2: '',
    zip: '1800',
    city: 'Vevey',
    location: {
      lat: 46.46483004235718,
      lng: 6.843171559219884
    },
    url: 'https://www.regieduboux.ch/',
    img: '/img/clients/duboux.png',
    alt: buildAlt('Régie Duboux - La Riviera')
  },
  {
    name: 'Régie Duboux SA - Succursale de Genève',
    agencyId: 'DUBOUXSAGENEVE',
    line1: 'Rue des Dissidents 4,',
    line2: '',
    zip: '1290',
    city: 'Versoix',
    location: {
      lat: 46.27687601157371,
      lng: 6.1690593656095025
    },
    url: 'https://www.regieduboux.ch/',
    img: '/img/clients/duboux.png',
    alt: buildAlt('Régie Duboux - Succursale de Genève')
  },
  // {
  //   name: 'Roland Savary Immobilier SA',
  //   agencyId: 'SAVARYYVERDON',
  //   line1: 'Le Bourg 15,',
  //   line2: '',
  //   zip: '1610',
  //   city: 'Oron',
  //   location: {
  //     lat: 46.57081792442873,
  //     lng: 6.826680578836042
  //   },
  //   url: 'https://www.savaryimmobilier.ch/',
  //   img: '/img/clients/savary.jpg',
  //   alt: buildAlt('Roland Savary Immobilier SA')
  // },
  // {
  //   name: 'Roland Savary Immobilier SA, succ Yverdon',
  //   agencyId: 'SAVARYYVERDON',
  //   line1: 'Rte de Lausanne 2,',
  //   line2: '',
  //   zip: '1400',
  //   city: 'Yverdon-les-Bains',
  //   location: {
  //     lat: 46.76891581032129,
  //     lng: 6.651786440488352
  //   },
  //   url: 'https://www.savaryimmobilier.ch/',
  //   img: '/img/clients/savary.jpg',
  //   alt: buildAlt('Roland Savary Immobilier SA, succ Yverdon')
  // },
  // {
  //   name: 'Roland Savary Immobilier SA, succ La Côte',
  //   agencyId: 'SAVARYCOTE',
  //   line1: 'Av. Reverdil 12,',
  //   line2: '',
  //   zip: '1260',
  //   city: 'Nyon',
  //   location: {
  //     lat: 46.3816028218941,
  //     lng: 6.232216338622833
  //   },
  //   url: 'https://www.savaryimmobilier.ch/',
  //   img: '/img/clients/savary.jpg',
  //   alt: buildAlt('Roland Savary Immobilier SA, succ La Côte')
  // },
  {
    name: 'Rosset Immobilier',
    agencyId: 'ROSSET',
    line1: 'Rte de Chancy 85',
    line2: '',
    zip: '1213',
    city: 'Onex',
    location: {
      lat: 46.18705863541081,
      lng: 6.108293138629378
    },
    url: 'https://www.rosset.ch/',
    img: '/img/clients/rosset.svg',
    alt: buildAlt('Rosset Immobilier')
  },
  {
    name: 'Rosset Immobilier - Fribourg',
    agencyId: 'ROSSETFRIBOURG',
    line1: 'Rue de Romont 5,',
    line2: '',
    zip: '1701',
    city: 'Fribourg',
    location: {
      lat: 46.80515399090213,
      lng: 7.155071054892254
    },
    url: 'https://www.rosset.ch/',
    img: '/img/clients/rosset.svg',
    alt: buildAlt('Rosset Immobilier - Fribourg')
  },
  {
    name: 'Rosset Immobilier - Lausanne',
    agencyId: 'ROSSETLAUSANNE',
    line1: 'Av. de Rumine 37,',
    line2: '',
    zip: '1005',
    city: 'Lausanne',
    location: {
      lat: 46.516221557065535,
      lng: 6.643268424190254
    },
    url: 'https://www.rosset.ch/',
    img: '/img/clients/rosset.svg',
    alt: buildAlt('Rosset Immobilier - Lausanne')
  },
  {
    name: 'VFP Immobilier SA',
    agencyId: 'VFP',
    line1: 'Rue de la Poste 15',
    line2: '',
    zip: '1936',
    city: 'Verbier',
    location: {
      lat: 46.09716888680549,
      lng: 7.2263719767132795
    },
    url: 'https://www.vfp.ch/',
    img: '/img/clients/vfp.svg',
    alt: buildAlt('VFP Immobilier')
  },
  {
    name: 'Bersier & Cie SA',
    agencyId: 'BERSIER',
    line1: 'Av. Eugène Lance 38BIS',
    line2: '',
    zip: '1212',
    city: 'Lancy',
    location: {
      lat: 46.17892073658909,
      lng: 6.124116492055935
    },
    url: 'https://www.bersiersa.ch/',
    img: '/img/clients/bersier.svg',
    alt: buildAlt('Bersier')
  },
  {
    name: 'énéo conseil en immobilier SA',
    agencyId: 'ENEO',
    line1: 'Rue de la Mairie 17',
    line2: '',
    zip: '1207',
    city: 'Genève',
    location: {
      lat: 46.20333369793242,
      lng: 6.158571853972033
    },
    url: 'https://www.eneo-immobilier.ch/',
    img: '/img/clients/eneo.jpg',
    alt: buildAlt('énéo conseil en immobilier SA')
  },
  {
    name: 'Caisse de pensions, canton de Neuchâtel',
    agencyId: 'CPCN',
    line1: 'Rue du Pont 23',
    line2: '',
    zip: '2300',
    city: 'La Chaux-de-Fonds',
    location: {
      lat: 47.10367481112607,
      lng: 6.831013056452946
    },
    url: 'https://gerance.cpcn.ch/',
    img: '/img/clients/cpcn.svg',
    alt: buildAlt('Caisse de pensions, canton de Neuchâtel ')
  },
  {
    name: 'Misa Management Immobilier SA',
    agencyId: 'MISA',
    line1: 'Av. de Montchoisi 15',
    line2: '',
    zip: '1006',
    city: 'Lausanne',
    location: {
      lat: 46.51387213649742,
      lng: 6.633705153972033
    },
    url: 'https://www.misa-gerance.ch/',
    img: '/img/clients/misa.svg',
    alt: buildAlt('Misa Management Immobilier SA')
  },
  {
    name: 'Moser Vernet & Cie SA',
    agencyId: 'MOSERVERNET',
    line1: 'Chem. Malombré 10',
    line2: '',
    zip: '1206',
    city: 'Genève',
    location: {
      lat: 46.19617373332946,
      lng: 6.1522514920559335
    },
    url: 'https://www.moservernet.ch/',
    img: '/img/clients/moser-vernet.svg',
    alt: buildAlt('Moser Vernet & Cie SA')
  },
  {
    name: 'Retraites Populaires - Lausanne',
    agencyId: 'RPLAUS',
    line1: 'Rue Caroline 9',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.5214876178456,
      lng: 6.637419744832111
    },
    url: 'https://www.retraitespopulaires.ch/immobilier',
    img: '/img/clients/rp.png',
    alt: buildAlt('Retraites Populaires - Lausanne')
  },
  {
    name: 'Retraites Populaires - Nyon',
    agencyId: 'RPNYON',
    line1: 'Rue Neuve 4',
    line2: '',
    zip: '1260',
    city: 'Nyon',
    location: {
      lat: 46.38334400188341,
      lng: 6.239044540658104
    },
    url: 'https://www.retraitespopulaires.ch/immobilier',
    img: '/img/clients/rp.png',
    alt: buildAlt('Retraites Populaires - Lausanne')
  },
  {
    name: 'Retraites Populaires - Yverdon',
    agencyId: 'RPYVE',
    line1: 'Rue de la Plaine 51',
    line2: '',
    zip: '1400 ',
    city: 'Yverdon-les-Bains',
    location: {
      lat: 46.77742442845113,
      lng: 6.644629528835664
    },
    url: 'https://www.retraitespopulaires.ch/immobilier',
    img: '/img/clients/rp.png',
    alt: buildAlt('Retraites Populaires - Yverdon')
  },
  {
    name: 'Société Privée de Gérance S.A. (SPG)',
    agencyId: 'SPG',
    line1: 'Rte de Chêne 36',
    line2: '',
    zip: '1208 ',
    city: 'Genève',
    location: {
      lat: 46.200400625637705,
      lng: 6.16868959260141
    },
    url: 'https://www.spg-rytz.ch/',
    img: '/img/clients/spg.svg',
    alt: buildAlt('Société Privée de Gérance S.A. (SPG)')
  },
  {
    name: 'Ville de Neuchâtel',
    agencyId: 'VILLENEUCH',
    line1: 'Faubourg du Lac 5',
    line2: '',
    zip: '2000',
    city: 'Neuchâtel',
    location: {
      lat: 46.99185074078156,
      lng: 6.931941226986017
    },
    url: 'https://www.neuchatelville.ch/vivre-a-neuchatel/habitat/gerance-et-logement',
    img: '/img/clients/vdn.jpg',
    alt: buildAlt('Ville de Neuchâtel')
  },
  // {
  //   name: 'Fortimo AG',
  //   agencyId: 'FORTIMO',
  //   line1: 'Rorschacher Str. 286',
  //   line2: '',
  //   zip: '9016',
  //   city: 'St. Gallen',
  //   location: {
  //     lat: 47.44366855373935,
  //     lng: 9.415404946027966
  //   },
  //   url: 'https://www.fortimo.ch/',
  //   img: '/img/clients/fortimo.jpg',
  //   alt: buildAlt('Fortimo AG')
  // },
  {
    name: 'Barrier Immobilien AG - Aarau',
    agencyId: 'BARRIERAA',
    line1: 'Rütmattstrasse 12',
    line2: '',
    zip: '5004',
    city: 'Aarau',
    location: {
      lat: 47.40103263213128,
      lng: 8.058815113493008
    },
    url: 'https://www.barrier.ch/',
    img: '/img/clients/barrier.png',
    alt: buildAlt('Barrier Immobilien AG - Aarau')
  },
  {
    name: 'Barrier Immobilien AG - Zürich',
    agencyId: 'BARRIERAZ',
    line1: 'Binzallee 4',
    line2: '',
    zip: '8055',
    city: 'Zürich',
    location: {
      lat: 47.363549511730376,
      lng: 8.5173613
    },
    url: 'https://www.barrier.ch/',
    img: '/img/clients/barrier.png',
    alt: buildAlt('Barrier Immobilien AG - Aarau')
  },
  {
    name: 'Régie Braun SA',
    agencyId: 'BRAUN',
    line1: 'Rue Centrale 5',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.521166649318495,
      lng: 6.632147
    },
    url: 'https://www.regiebraun.ch/',
    img: '/img/clients/braun.png',
    alt: buildAlt('Régie Braun SA')
  },
  {
    name: 'Grange & Cie',
    agencyId: 'GRANGE',
    line1: 'Chem. de Grange-Canal 23',
    line2: '',
    zip: '1208',
    city: 'Genève',
    location: {
      lat: 46.20189545395713,
      lng: 6.1756731767215545
    },
    url: 'https://www.grange.ch/',
    img: '/img/clients/grange-black-background.png',
    alt: buildAlt('Grange & Cie')
  },
  {
    name: 'TJCA',
    agencyId: 'TJCA',
    line1: 'Chem. de la Bessonnette 7,',
    line2: '',
    zip: '1224',
    city: 'Chêne-Bougeries',
    location: {
      lat: 46.1963673805448,
      lng: 6.188873287271529
    },
    url: 'https://tjca.swiss/',
    img: '/img/clients/TJCA.png',
    alt: buildAlt('Grange & Cie')
  },
  //  {
  //    name: 'Bolliger immobilier',
  //    agencyId: 'BOLLIGER',
  //    line1: 'Av. Léopold-Robert 12',
  //    line2: '',
  //    zip: '2300',
  //    city: 'La Chaux-de-Fonds,',
  //    location: {
  //      lat: 47.10354389069969,
  //      lng: 6.829968615342657
  //    },
  //    url: 'https://www.bolliger-immobilier.ch/',
  //    img: '/img/clients/bolliger.jpg',
  //    alt: buildAlt('Bolliger immobilier')
  //  },
  {
    name: 'Espace Real Estate',
    agencyId: 'ESPACE',
    line1: 'Zuchwilerstrasse Str. 43',
    line2: '',
    zip: '4500',
    city: 'Solothurn',
    location: {
      lat: 47.20304,
      lng: 7.54408
    },
    url: 'https://espacereal.ch/',
    img: '/img/clients/espace.svg',
    alt: buildAlt('Espace Real Estate')
  },
  // {
  //   name: 'SiClaro - Arbon',
  //   agencyId: 'SICLAROARBON',
  //   line1: 'Romanshornerstrasse 4',
  //   line2: '',
  //   zip: '9320',
  //   city: 'Arbon',
  //   location: {
  //     lat: 47.51698369846884,
  //     lng: 9.431872326427914
  //   },
  //   url: 'https://si-claro.ch/',
  //   img: '/img/clients/siclaro.png',
  //   alt: buildAlt('SiClaro - Arbon')
  // },
  // {
  //   name: 'SiClaro - St. Gallen',
  //   agencyId: 'SICLAROALLEN',
  //   line1: 'Oberer Graben 8',
  //   line2: '',
  //   zip: '9000',
  //   city: 'St. Gallen',
  //   location: {
  //     lat: 47.425429560293146,
  //     lng: 9.37404249758716
  //   },
  //   url: 'https://si-claro.ch/',
  //   img: '/img/clients/siclaro.png',
  //   alt: buildAlt('SiClaro - Arbon')
  // },
  {
    name: 'Baugenossenschaft Sonnengarten',
    agencyId: 'BGSONNENGARTEN',
    line1: 'Triemlistrasse 22',
    line2: '',
    zip: '8047',
    city: 'Zürich',
    location: {
      lat: 47.37346575479167,
      lng: 8.484445055257456
    },
    url: 'https://www.bg-sonnengarten.ch/#',
    img: '/img/clients/sonnengarten.png',
    alt: buildAlt('Baugenossenschaft Sonnengarten')
  },
  // {
  //   name: 'Wolf Treuhand',
  //   agencyId: 'WOLF',
  //   line1: 'Stedtligass 2',
  //   line2: '',
  //   zip: '8627',
  //   city: 'Grüningen',
  //   location: {
  //     lat: 47.283749955237795,
  //     lng: 8.765441438053275
  //   },
  //   url: 'https://wolf-treuhand.ch/',
  //   img: '/img/clients/wolf.jpg',
  //   alt: buildAlt('Wolf Treuhand')
  // },
  {
    name: 'IBSG',
    agencyId: 'IBSG',
    line1: 'Lagerstrasse 4',
    line2: '',
    zip: '9200',
    city: 'Gossau',
    location: {
      lat: 47.41145028890157,
      lng: 9.274863612147287
    },
    url: 'https://www.ibsg.ch/',
    img: '/img/clients/IBSG.png',
    alt: buildAlt('Immobilien Bewirtschaftung')
  },
  {
    name: 'Eugen Schäfer AG',
    agencyId: 'SCHAFER',
    line1: 'Brüelstrasse 18',
    line2: '',
    zip: '8157',
    city: 'Dielsdorf',
    location: {
      lat: 47.48502685670059,
      lng: 8.466305238618235
    },
    url: 'https://schaeferag.ch/',
    img: '/img/clients/eugen.png',
    alt: buildAlt('Eugen Schäfer AG')
  },
  {
    name: 'HIG Immobilien Anlage Stiftung',
    agencyId: 'HIG',
    line1: 'Löwenstrasse 25',
    line2: '',
    zip: '8001',
    city: 'Zürich',
    location: {
      lat: 47.37454818649121,
      lng: 8.53555757060296
    },
    url: 'https://www.hig.ch/',
    img: '/img/clients/hig.svg',
    alt: buildAlt('HIG Immobilien Anlage Stiftung')
  },
  {
    name: 'EKZ',
    agencyId: 'EKZ',
    line1: 'Dreikönigstrasse 18',
    line2: '',
    zip: '8002',
    city: 'Zürich',
    location: {
      lat: 47.36744263592175,
      lng: 8.536524811075381
    },
    url: 'https://www.ekz.ch/de/ueber-ekz.html',
    img: '/img/clients/ekz.svg',
    alt: buildAlt('EKZ')
  },
  //  {
  //    name: 'Lotus Immobilientreuhand GmbH',
  //    agencyId: 'LOTUS',
  //    line1: 'Belpbergstrasse 43',
  //    line2: '',
  //    zip: '3110',
  //    city: 'Münsingen',
  //    location: {
  //      lat: 46.86953192961017,
  //      lng: 7.556641599415572
  //    },
  //    url: 'https://www.lotusimmo.ch/',
  //    img: '/img/clients/lotus.png',
  //    alt: buildAlt('Lotus Immobilientreuhand GmbH')
  //  },
  {
    name: 'Sessler Immo',
    agencyId: 'LOTUS',
    line1: 'Jean-Sessler-Strasse 1',
    line2: '',
    zip: '2502',
    city: 'Biel/Bienne',
    location: {
      lat: 47.13794653406479,
      lng: 7.245964012919069
    },
    url: 'https://sesslerimmo.ch/',
    img: '/img/clients/sessler.svg',
    alt: buildAlt('Sessler Immo')
  },
  {
    name: 'Régie Châtel',
    agencyId: 'CHATEL',
    line1: 'Av. de la Gare 26',
    line2: '',
    zip: '1618',
    city: 'Châtel-St-Denis',
    location: {
      lat: 46.52665145883719,
      lng: 6.898960624526853
    },
    url: 'https://www.regiechatel.ch/',
    img: '/img/clients/regiechatel.png',
    alt: buildAlt('Régie Châtel')
  },
  {
    name: 'OFISA',
    agencyId: 'OFISA',
    line1: 'Chemin de la Rueyre 120',
    line2: '',
    zip: '1020',
    city: 'Renens',
    location: {
      lat: 46.544874579373804,
      lng: 6.58950617056406
    },
    url: 'https://www.o-i.ch/',
    img: '/img/clients/ofisa.svg',
    alt: buildAlt('OFISA')
  },
  {
    name: 'AXEPT',
    agencyId: 'AXEPT',
    line1: 'Kemptpark 12',
    line2: '',
    zip: '8310',
    city: 'Kemptthal',
    location: {
      lat: 46.544874579373804,
      lng: 6.58950617056406
    },
    url: 'https://www.axept.ch/',
    img: '/img/clients/axept.png',
    alt: buildAlt('AXEPT')
  },
  {
    name: 'Customize',
    agencyId: 'CUSTOMIZE',
    line1: 'Oberer Graben 22',
    line2: '',
    zip: '9000',
    city: 'St. Gallen',
    location: {
      lat: 47.42336191002576,
      lng: 9.374106056332874
    },
    url: 'https://customize.ch/',
    img: '/img/clients/customize.svg',
    alt: buildAlt('Customize')
  },
  {
    name: 'Vaudoise - Argovie',
    agencyId: 'VAUDOISEARGO',
    line1: 'Kasernenstrasse 26',
    line2: '',
    zip: '5000',
    city: 'Aarau',
    location: {
      lat: 47.39212846670586,
      lng: 8.049181070925359
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Argovie')
  },
  // {
  //   name: 'Vaudoise - Aubonne',
  //   agencyId: 'VAUDOISEAUBONNE',
  //   line1: 'Rue du Trévelin 7',
  //   line2: '',
  //   zip: '1170',
  //   city: 'Aubonne',
  //   location: {
  //     lat: 46.49488147778376,
  //     lng: 6.391500895024918
  //   },
  //   url: 'https://www.vaudoise.ch/',
  //   img: '/img/clients/vaudoise.png',
  //   alt: buildAlt('Vaudoise - Aubonne')
  // },
  {
    name: 'Vaudoise - Bâle',
    agencyId: 'VAUDOISEAUBONNE',
    line1: 'Steinengraben 55',
    line2: '',
    zip: '4051',
    city: 'Bâle',
    location: {
      lat: 47.55374984795016,
      lng: 7.586305423910148
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Bâle')
  },
  {
    name: 'Vaudoise - Delémont',
    agencyId: 'VAUDOISEDELEMONT',
    line1: 'Jeanne Haas-Ulmann 2',
    line2: '',
    zip: '2800',
    city: 'Delémont',
    location: {
      lat: 47.362756114111754,
      lng: 7.347505968076124
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Delémont')
  },
  {
    name: 'Vaudoise - Berne',
    agencyId: 'VAUDOISEBERNE',
    line1: 'Thunstrasse 20',
    line2: '',
    zip: '3005',
    city: 'Berne',
    location: {
      lat: 46.942217270596856,
      lng: 7.453326323881041
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Berne')
  },
  {
    name: 'Vaudoise - Zürich',
    agencyId: 'VAUDOISEZURICH',
    line1: 'Stampfenbachstrasse 40',
    line2: '',
    zip: '8006',
    city: 'Zürich',
    location: {
      lat: 47.38009301410903,
      lng: 8.543238510406931
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Zürich')
  },
  // {
  //   name: 'Vaudoise - Bulle',
  //   agencyId: 'VAUDOISEBULLE',
  //   line1: 'Rue Nicolas Glasson 7',
  //   line2: '',
  //   zip: '1630',
  //   city: 'Bulle',
  //   location: {
  //     lat: 46.617418010417964,
  //     lng: 7.055537708525609
  //   },
  //   url: 'https://www.vaudoise.ch/',
  //   img: '/img/clients/vaudoise.png',
  //   alt: buildAlt('Vaudoise - Bulle')
  // },
  {
    name: 'Vaudoise - Genève',
    agencyId: 'VAUDOISEGE',
    line1: 'Boulevard du Théâtre 9',
    line2: '',
    zip: '1204',
    city: 'Genève',
    location: {
      lat: 46.20237933150287,
      lng: 6.142170941031524
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Genève')
  },
  {
    name: 'Vaudoise - Lugano',
    agencyId: 'VAUDOISELUGANO',
    line1: 'Via Nassa 29',
    line2: '',
    zip: '6900',
    city: 'Lugano',
    location: {
      lat: 46.00084885754564,
      lng: 8.949518524890848
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Lugano')
  },
  {
    name: 'Vaudoise - Lausanne',
    agencyId: 'VAUDOISELAUSANNE',
    line1: 'Rue Pichard 22',
    line2: '',
    zip: '1003',
    city: 'Lausanne',
    location: {
      lat: 46.52150914923146,
      lng: 6.630695980887812
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Lausanne')
  },
  {
    name: 'Vaudoise - Morges',
    agencyId: 'VAUDOISEMORGES',
    line1: 'Rue des Charpentiers 9',
    line2: '',
    zip: '1110',
    city: 'Morges',
    location: {
      lat: 46.51007134881808,
      lng: 6.496950454540846
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Morges')
  },
  {
    name: 'Vaudoise - Sion',
    agencyId: 'VAUDOISESION',
    line1: 'Place du Midi 36',
    line2: '',
    zip: '1950',
    city: 'Sion',
    location: {
      lat: 46.231467095757694,
      lng: 7.3614360552040194
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Sion')
  },
  {
    name: 'Vaudoise - Coire',
    agencyId: 'VAUDOISECOIRE',
    line1: 'Steinbockstrasse 4',
    line2: '',
    zip: '7000',
    city: 'Coire',
    location: {
      lat: 46.85241398078554,
      lng: 9.52946788406933
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Coire')
  },
  {
    name: 'Vaudoise - Fribourg',
    agencyId: 'VAUDOISEFRIB',
    line1: 'Rue Saint-Pierre 18',
    line2: '',
    zip: '1700',
    city: 'Fribourg',
    location: {
      lat: 46.80406678496727,
      lng: 7.156057454554709
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Fribourg')
  },
  {
    name: 'Vaudoise - Soleure',
    agencyId: 'VAUDOISESOLEURE',
    line1: 'Niklaus-Konrad-Strasse 27',
    line2: '',
    zip: '4500',
    city: 'Soleure',
    location: {
      lat: 47.20528793359239,
      lng: 7.54219335767272
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Soleure')
  },
  {
    name: 'Vaudoise - Payerne',
    agencyId: 'VAUDOISEPAYERNE',
    line1: 'Grand-Rue 2',
    line2: '',
    zip: '1530',
    city: 'Payerne',
    location: {
      lat: 46.822307630408204,
      lng: 6.939878913863281
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Payerne')
  },
  {
    name: 'Vaudoise - Neuchâtel',
    agencyId: 'VAUDOISENEUCH',
    line1: 'Rue du Musée 5',
    line2: '',
    zip: '2000',
    city: 'Neuchâtel',
    location: {
      lat: 46.98971873763552,
      lng: 6.9300464831772155
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Neuchâtel')
  },
  {
    name: 'Vaudoise - Renens',
    agencyId: 'VAUDOISERENENS',
    line1: 'Avenue de la Poste 1',
    line2: '',
    zip: '1020',
    city: 'Renens',
    location: {
      lat: 46.53944701822805,
      lng: 6.583757956182442
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Renens')
  },
  {
    name: 'Vaudoise - Yverdon',
    agencyId: 'VAUDOISEYVERDON',
    line1: 'En Chamard 35',
    line2: '',
    zip: '1442',
    city: 'Montagny-près-Yverdon',
    location: {
      lat: 46.792234385269595,
      lng: 6.620299215676192
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Yverdon')
  },
  {
    name: 'Vaudoise - Brig',
    agencyId: 'VAUDOISEBRIG',
    line1: 'Kapuzinerstrasse 1',
    line2: '',
    zip: '3900',
    city: 'Brig',
    location: {
      lat: 46.31580586331355,
      lng: 7.987646256176064
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Brig')
  },
  {
    name: 'Vaudoise - St-Gallen',
    agencyId: 'VAUDOISESTGALL',
    line1: 'Oberer Graben 26 ',
    line2: '',
    zip: '9000',
    city: 'St-Gallen',
    location: {
      lat: 47.4230739629376,
      lng: 9.374137527371566
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - St-Gallen')
  },
  {
    name: 'Vaudoise - Rapperswil',
    agencyId: 'VAUDOISERAPPERSWIL',
    line1: 'Jägerweg 22',
    line2: '',
    zip: '8640',
    city: 'Rapperswil SG',
    location: {
      lat: 47.22747233499444,
      lng: 8.822044598529558
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Rapperswil')
  },
  {
    name: 'Vaudoise - Vevey',
    agencyId: 'VAUDOISEVEVEY',
    line1: 'Rue du Simplon 45',
    line2: '',
    zip: '1800',
    city: 'Vevey',
    location: {
      lat: 46.46131708454663,
      lng: 6.843448815594628
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Vevey')
  },
  {
    name: 'Vaudoise - Winterthour',
    agencyId: 'VAUDOISEWINTERTHOUR',
    line1: 'Zürcherstrasse 46',
    line2: '',
    zip: '8400',
    city: 'Winterthour',
    location: {
      lat: 47.496337857775764,
      lng: 8.713503240864556
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Winterthour')
  },
  {
    name: 'Vaudoise - Lucerne',
    agencyId: 'VAUDOISELUCERNE',
    line1: 'Obergrundstrasse 50',
    line2: '',
    zip: '6003',
    city: 'Lucerne',
    location: {
      lat: 47.045608084535665,
      lng: 8.303761627360656
    },
    url: 'https://www.vaudoise.ch/',
    img: '/img/clients/vaudoise.png',
    alt: buildAlt('Vaudoise - Lucerne')
  },
  {
    name: 'H&B Real Estate AG',
    agencyId: 'H&BZURICH',
    line1: 'Lagerstrasse 107',
    line2: '',
    zip: '8004',
    city: 'Zürich',
    location: {
      lat: 47.379929887565496,
      lng: 8.528850082007946
    },
    url: 'https://www.hbre.ch/',
    img: '/img/clients/H&B.svg',
    alt: buildAlt('H&B')
  },
  {
    name: 'Procimmo',
    agencyId: 'PROCIMMOZURICH',
    line1: 'Löwenstrasse 20',
    line2: '',
    zip: '8001',
    city: 'Zürich',
    location: {
      lat: 47.37405757899962,
      lng: 8.535362306036696
    },
    url: 'https://procimmo.ch/de',
    img: '/img/clients/procimmo.png',
    alt: buildAlt('Procimmo - Zürich')
  },
  {
    name: 'Procimmo',
    agencyId: 'PROCIMMOLAUSANNE',
    line1: 'Rue de Lausanne 64',
    line2: '',
    zip: '1020',
    city: 'Renens',
    location: {
      lat: 46.5802205964369,
      lng: 6.570682541654825
    },
    url: 'https://procimmo.ch/de',
    img: '/img/clients/procimmo.png',
    alt: buildAlt('Procimmo - Lausanne')
  },
  {
    name: 'Procimmo',
    agencyId: 'PROCIMMOGENEVA',
    line1: 'Rue du Marché 20',
    line2: '',
    zip: '1204',
    city: 'Genève',
    location: {
      lat: 46.20329938395055,
      lng: 6.1466711958849105
    },
    url: 'https://procimmo.ch/de',
    img: '/img/clients/procimmo.png',
    alt: buildAlt('Procimmo - Geneva')
  },
  {
    name: 'Karmon AG',
    agencyId: 'KARMONAG',
    line1: 'Badenerstrasse 329',
    line2: '',
    zip: '8003',
    city: 'Zürich',
    location: {
      lat: 47.37762906164487,
      lng: 8.511675382007834
    },
    url: 'https://www.karmon.ch/',
    img: '/img/clients/karmon.svg',
    alt: buildAlt('Karmon')
  },
  {
    name: 'Beeler Immobilien AG',
    agencyId: 'BEELER',
    line1: 'Chileweg 6c',
    line2: '',
    zip: '8917',
    city: 'Oberlunkhofen',
    location: {
      lat: 47.3121135778335,
      lng: 8.39047521157757
    },
    url: 'https://www.beeler-immobilien.ch/',
    img: '/img/clients/beeler.png',
    alt: buildAlt('Beeler')
  },
  {
    name: 'Böni Immobilien AG',
    agencyId: 'BOENI',
    line1: 'Zürcherstrasse 350',
    line2: '',
    zip: '8500',
    city: 'Frauenfeld',
    location: {
      lat: 47.56758746754435,
      lng: 8.91603116667152
    },
    url: 'https://www.beeler-immobilien.ch/',
    img: '/img/clients/boeni.svg',
    alt: buildAlt('Böni')
  }

  // {
  //   name: '',
  //   agencyId: '',
  //   line1: '',
  //   line2: '',
  //   zip: '',
  //   city: '',
  //   location: {
  //     lat: 0,
  //     lng: 0
  //   },
  //   url: '',
  //   img: '',
  //   alt: buildAlt('')
  // }
]

const moteur = [
  {
    name: 'Tayo - Moteur! | E1-S1 ',
    season: 1,
    line1: 'Robin Crisinel',
    line2: 'Directeur Régie Duboux SA ',
    youtube: 'https://www.youtube.com/watch?v=gZtPyiaRBrI',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6847036677764993024',
    agencyId: 'DUBOUX',
    location: {
      lat: 46.5187648,
      lng: 6.6311061
    },
    img: '/img/moteur/1.png',
    isMoteur: true,
    alt: buildAltMoteur('Robin Crisinel, Duboux'),
    language: ['fr']
    // lang: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E2-S1 ',
    season: 1,
    line1: 'Vincent Rosselet',
    line2: 'Directeur de Gestigroup',
    youtube: 'https://www.youtube.com/watch?v=EsptLUtUMRo',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6849585096589090817',
    agencyId: 'GESTIGROUP',
    location: {
      lat: 46.614537,
      lng: 6.5077802
    },
    img: '/img/moteur/2.png',
    alt: buildAltMoteur('Vincent Rosselet, Gestigroup'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E3-S1 ',
    season: 1,
    line1: 'Stefanie Sidler',
    line2: 'Directrice de la Gérance Bory & Cie SA',
    youtube: 'https://www.youtube.com/watch?v=PEgpBLuxOV8',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6852094224653930496',
    agencyId: 'BORY',
    location: {
      lat: 46.2024868,
      lng: 6.1716021
    },
    img: '/img/moteur/3.png',
    alt: buildAltMoteur('Stefanie Sidler, Bory & Cie SA'),
    isMoteur: true,
    language: ['fr']
  },
  { // @TODO : Replace location from here
    name: 'Tayo - Moteur! | E4-S1',
    season: 1,
    line1: 'Thierry Martin',
    line2: 'Directeur de la Régie Jacques Lugrin SA',
    youtube: 'https://www.youtube.com/watch?v=Z61UpDXLCYE',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6854659991941484544',
    agencyId: 'JACQUESLUGRIN',
    location: {
      lat: 46.508617,
      lng: 6.496312
    },
    img: '/img/moteur/4.png',
    alt: buildAltMoteur('Thierry Martin, Jacques Lugrin SA'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E5-S1 ',
    season: 1,
    line1: 'Luigi Rota',
    line2: 'Directeur de FIVC',
    youtube: 'https://www.youtube.com/watch?v=eY-5rYvxVN8',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6857208450716340224',
    agencyId: 'FIVC',
    location: {
      lat: 46.1900249,
      lng: 6.1356011
    },
    img: '/img/moteur/5.png',
    alt: buildAltMoteur('Luigi Rota, FIVC'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E6-S1 ',
    season: 1,
    line1: 'Baptiste Morand',
    line2: 'Directeur Régie de Fribourg SA',
    youtube: 'https://www.youtube.com/watch?v=YfRoWhkECZs',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6859710517670359040',
    agencyId: 'FRIBOURGSA',
    location: {
      lat: 46.8036534,
      lng: 7.1542302
    },
    img: '/img/moteur/6.png',
    alt: buildAltMoteur('Baptiste Morand, Fribourg SA'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E7-S1 ',
    season: 1,
    line1: 'Olivier Moser',
    line2: 'Directeur de Rham SA',
    youtube: 'https://www.youtube.com/watch?v=oHhBW708QAk',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6862298770776838144',
    agencyId: 'DERHAMSA',
    location: {
      lat: 46.5186643,
      lng: 6.6405072
    },
    img: '/img/moteur/7.png',
    alt: buildAltMoteur('Olivier Moser, de Rham SA'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E8-S1 ',
    season: 1,
    line1: 'Florence Prini Saggio',
    line2: 'Directrice de Beaver Immobilier SA',
    youtube: 'https://www.youtube.com/watch?v=kWwSko-dwDM',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6864830713032658945',
    agencyId: 'BEAVERIMMO',
    location: {
      lat: 46.1909746, // @TODO : Info --> Coaching - leur signaler qu'il n'apparaissent pas sur gmap si on tape leur nom dans google
      lng: 6.1113664
    },
    img: '/img/moteur/9.png',
    alt: buildAltMoteur('Prini Saggio, Beaver Immobilier'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E9-S1 ',
    season: 1,
    line1: 'Luc Ducommun',
    line2: 'Administrateur Menuiserie Ducommun SA',
    youtube: 'https://www.youtube.com/watch?v=dQfwLZDet5M',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6867345461951385600',
    agencyId: 'DUCOMMUN',
    location: {
      lat: 46.5337798,
      lng: 6.6341946
    },
    img: '/img/moteur/8.png',
    alt: buildAltMoteur('Luc Ducommun, Menuiserie Ducommun SA'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | F10-S1',
    season: 1,
    line1: 'Beat Siegenthaler',
    line2: 'Geschäftsführer Deutschschweiz Tayo SA',
    youtube: 'https://www.youtube.com/watch?v=t6HcmAt_PaE',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6869883711005986816',
    agencyId: 'BEAT_TAYO',
    location: {
      lat: 47.34197163108855,
      lng: 8.571924784657345
    },
    img: '/img/moteur/10.png',
    alt: buildAltMoteur('Beat Siegenthaler, Tayo SA'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F11-S1',
    season: 1,
    agencyId: 'SANJO_MOTEUR',
    line1: 'Ramon J. Kälin',
    line2: 'CEO der Sanjo Management AG',
    youtube: 'https://www.youtube.com/watch?v=817WPUyqJkA',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6872433924983812096/',
    location: {
      lat: 47.19366249862568,
      lng: 8.822068138629378
    },
    img: '/img/moteur/11.png',
    alt: buildAltMoteur('Ramon J. Kälin, Sanjo Management AG'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | E12-S1 ',
    season: 1,
    line1: 'Etienne Friedli',
    line2: 'CEO Tayo SA',
    agencyId: 'ETIENNE_MOTEUR',
    youtube: 'https://www.youtube.com/watch?v=eI-aYceIuV8',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6874963679578738688',
    location: {
      lat: 46.517238405558,
      lng: 6.5613224772355
    },
    img: '/img/moteur/12.png',
    alt: buildAltMoteur('Etienne Friedli, CEO Tayo'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E1-S2',
    season: 2,
    line1: 'Claude Frei',
    line2: 'Co-fondateur & CSO de Tayo SA',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6917709781663166464',
    agencyId: 'CLAUDE_MOTEUR',
    location: {
      lat: 46.51723840555795,
      lng: 6.561322477235479
    },
    img: '/img/moteur/13.png',
    alt: buildAltMoteur('Claude Frei, Tayo SA'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | F2-S2',
    season: 2,
    line1: 'Philipp Zwahlen',
    line2: 'Head of SBU AbaImmo bei Abacus Research AG',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6932932750576390144',
    location: {
      lat: 47.4510895302956,
      lng: 9.396451253960649
    },
    agencyId: 'AbaImmo_MOTEUR',
    img: '/img/moteur/14.png',
    alt: buildAltMoteur('Philipp Zwahlen, Abacus Research AG'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F3-S2',
    season: 2,
    line1: 'Claudio Walde',
    line2: 'Leiter Anlageimmobilien bei Walde Immobilien AG',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6935105293982822400',
    location: {
      lat: 47.34191347036942,
      lng: 8.571710207926618
    },
    agencyId: 'Immobilien_MOTEUR',
    img: '/img/moteur/15.png',
    alt: buildAltMoteur('Claudio Walde, Walde Immobilien AG'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F4-S2',
    season: 2,
    line1: 'Stefan Zanetti',
    line2: ' Gründer und VRP bei Allthings',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6938040781244915712',
    location: {
      lat: 47.54954029141983,
      lng: 7.598504067698833
    },
    agencyId: 'Allthings_MOTEUR',
    img: '/img/moteur/16.png',
    alt: buildAltMoteur('Stefan Zanetti, Allthings'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F5-S2',
    season: 2,
    line1: 'Raphael Nicolas Schuler',
    line2: 'Senior Sales bei MV Invest AG',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6940563058490523648',
    location: {
      lat: 47.36107096586745,
      lng: 8.551483027730807
    },
    agencyId: 'MVINVEST_MOTEUR',
    img: '/img/moteur/17.png',
    alt: buildAltMoteur('Raphael Nicolas Schuler, MV Invest AG'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F6-S2',
    season: 2,
    line1: 'Valérie Henger',
    line2: 'Unternehmensinhaberin der Henger Immobilien GmbH',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6943160825473843200',
    location: {
      lat: 47.24772257431516,
      lng: 8.631486212383251
    },
    agencyId: 'GMBH_MOTEUR',
    img: '/img/moteur/18.png',
    alt: buildAltMoteur('Valérie Henger, Henger Immobilien GmbH'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F7-S2',
    season: 2,
    line1: 'Cornelia Hannig',
    line2: 'Direktorin bei Firstcaution SA',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6945618253746475008',
    location: {
      lat: 47.24775898957728,
      lng: 8.631572043074378
    },
    agencyId: 'FIRSTCAUTION_MOTEUR',
    img: '/img/moteur/19.png',
    alt: buildAltMoteur('Luc Ducommun, Menuiserie Ducommun SA'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | E8-S2',
    season: 2,
    line1: 'Vincent Messerli',
    line2: 'PhD, PMP, Chef de projets Retraites Populaires',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6973501101077622784/',
    location: {
      lat: 46.5213881181344,
      lng: 6.6370231693158726
    },
    agencyId: 'RP_MESSERLI_MOTEUR',
    img: '/img/moteur/24.png',
    alt: buildAltMoteur('Vincent Messerli, Retraites Populaires'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | F9-S2',
    season: 2,
    line1: 'Talita Landis',
    line2: 'Leiterin Immobilienbuchhaltung und Digitalisierung bei AUWIESEN IMMOBILIEN AG',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6976133352298815488',
    location: {
      lat: 47.488871797149635,
      lng: 8.704850469315874
    },
    agencyId: 'AUWIESEN_IMMOBILIEN_AG_MOTEUR',
    img: '/img/moteur/20.png',
    alt: buildAltMoteur('Talita Landis -  AUWIESEN IMMOBILIEN AG'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F10-S2',
    season: 2,
    line1: 'Markus und Michael',
    line2: 'Co-CEOs Bonacasa AG',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6978591397171892224',
    location: {
      lat: 47.290625894325174,
      lng: 7.717722422736512
    },
    agencyId: 'BONOCASA_MOTEUR',
    img: '/img/moteur/21.png',
    alt: buildAltMoteur('Markus und Michael, Bonacasa AG'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | F11-S2',
    season: 2,
    line1: 'Yannick Tinguely',
    line2: 'Geschäftsführer Deutschschweiz bei Signa-Terre SA',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6981522307487834112',
    location: {
      lat: 46.95954628759315,
      lng: 7.490864569315873
    },
    agencyId: 'SIGNATERRE_MOTEUR',
    img: '/img/moteur/22.png',
    alt: buildAltMoteur('Yannick Tinguely - Signa-Terre SA'),
    isMoteur: true,
    language: ['de']
  },
  {
    name: 'Tayo - Moteur! | E12-S2',
    season: 2,
    line1: 'Carine Zehnder',
    line2: 'Gérante d’immeubles à Retraites Populaires',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6986190566287908864',
    location: {
      lat: 46.52132905940143,
      lng: 6.63732357671032
    },
    agencyId: 'RP_ZENDER_MOTEUR',
    img: '/img/moteur/23.png',
    alt: buildAltMoteur('Carine Zehnder - Retraites Populaires'),
    isMoteur: true,
    language: ['fr']
  },
  {
    name: 'Tayo - Moteur! | E13-S2',
    season: 2,
    line1: 'Sébastien Henchoz',
    line2: 'Responsable du service patrimoine à Retraites Populaires',
    linkedin: 'https://www.linkedin.com/feed/update/urn:li:activity:6988860159896739841',
    location: {
      lat: 46.52132905940143,
      lng: 6.63732357671032
    },
    agencyId: 'RP_HENCHOZ_MOTEUR',
    img: '/img/moteur/25.png',
    alt: buildAltMoteur('Sébastien Henchoz - Retraites Populaires'),
    isMoteur: true,
    language: ['fr']
  }
  // {
  //   name: 'Tayo - Moteur! | F7-S2',
  //   season: 2,
  //   line1: '',
  //   line2: '',
  //   linkedin: '',
  //   location: {
  //     lat: 0,
  //     lng: 0
  //   },
  //   agencyId: '',
  //   img: '',
  //   alt: buildAltMoteur(''),
  //   isMoteur: true,
  //   language: ['de']
  // },

]

export default {
  AGENCIES: agencies,
  MOTEUR: moteur
}
