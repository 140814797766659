<template>
  <v-btn
    style="background-color: rgba(255, 255, 255, 0)"
    class="ma-0 back-button"
    tile
    flat
    aria-label="Go back"
    @click="$router.go(-1)"
  >
    <v-icon
      left
      class="mr-4"
      :icon="mdiArrowLeft"
    />
    {{ $t('button.goback') }}
  </v-btn>
</template>
<script>
import { mdiArrowLeft } from '@mdi/js'
export default {
  data: () => ({
    mdiArrowLeft
  })
}
</script>
