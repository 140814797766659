<template>
  <v-btn
    v-if="btnInit"
    :to="target"
    class="tayo-btn pr-5"
    theme="light"
    :aria-label="label"
    height="40px"
    flat
    rounded="pill"
    :append-icon="mdiArrowRight"
  >
    {{ label }}
  </v-btn>
</template>

<script>
import { mdiArrowRight } from '@mdi/js'

export default {
  props: {
    link: {
      type: String,
      default: '#'
    },
    text: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    mdiArrowRight,
    btnInit: false
  }),
  computed: {
    label () {
      if (this.text && this.text !== '') {
        return this.text
      } else if (this.buttonType) {
        switch (this.buttonType) {
          case 'demo':
            if (
              this.$vuetify.display.mobile ||
              this.$vuetify.display.width < 1100
            ) {
              return this.$t('button.demo')
            } else {
              return this.$t('button.request_demo')
            }
          case 'community':
            return this.$t('button.community')
          default:
            return ''
        }
      } else {
        return ''
      }
    },
    target () {
      if (this.link && this.link !== '#') {
        return this.link
      } else if (this.buttonType) {
        switch (this.buttonType) {
          case 'demo':
            return '/' + this.$i18n.locale + '/request-demo'
          case 'community':
            return '/' + this.$i18n.locale + '/tayo-map'
          default:
            return '#'
        }
      } else {
        return '#'
      }
    }
  },
  mounted () {
    this.btnInit = true
  }
}
</script>
<style>
</style>
